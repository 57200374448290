// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mug-js": () => import("/opt/build/repo/src/pages/mug.js" /* webpackChunkName: "component---src-pages-mug-js" */),
  "component---src-pages-previous-mugs-js": () => import("/opt/build/repo/src/pages/previous-mugs.js" /* webpackChunkName: "component---src-pages-previous-mugs-js" */),
  "component---src-pages-success-rate-js": () => import("/opt/build/repo/src/pages/success-rate.js" /* webpackChunkName: "component---src-pages-success-rate-js" */)
}

